export enum RedirectApp {
  Pawblisher = 'pawblisher',
  Teletriage = 'teletriage',
  Flow = 'flow',
}

export const getRedirectUrl = (): string | null => {
  const params = new URL(window.location.href).searchParams;
  const redirect = params.has('redirect') ? `redirect=${params.get('redirect')}` : null;

  return redirect;
};

export const isPawblisherRedirect = (): boolean => {
  const params = new URL(window.location.href).searchParams;
  if (params.has('redirectApp')) {
    return params.get('redirectApp') === RedirectApp.Pawblisher;
  }
  return false;
};

export const getPawblisherRedirectUrl = (customtoken: string): string => {
  const host = window.location.host;
  const ending = `admin?token=${customtoken}`;
  if (host.includes('localhost')) {
    return `http://localhost:3000/${ending}`;
  } else if (host.includes('staging')) {
    return `https://pawblisher.demo.care.vet/${ending}`;
  } else if (host.includes('.dev.')) {
    const subdomain = host.split('.')[1];
    return `https://pawblisher.${subdomain}.dev.care.vet/${ending}`;
  } else {
    return `https://pawblisher.care.vet/${ending}`;
  }
};

export const getPawblisherLoginUrl = (): string => {
  return `redirectApp=${RedirectApp.Pawblisher}`;
};
